import * as React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BankSupport } from "@/@types/bank";
import jsonSupportBanks from "@/@data/supportBanks.json";
import { useSignUpStore } from "@/stores/signUpStore";
import { shallow } from "zustand/shallow";
import { apiPath } from "@/configs/apiPath";
import http from "@/libs/http";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useConfigStore } from "@/stores/configStore";

const supportBanks: BankSupport[] = jsonSupportBanks;

type Inputs = {
  bankCode: string;
  bankAccountNumber: string;
};

const schema = yup.object().shape({
  bankCode: yup.string().required("กรุณาเลือกธนาคาร"),
  bankAccountNumber: yup
    .string()
    .min(10, "เลขที่บัญชีธนาคารต้องมีอย่างน้อย 10 ตัวเลข")
    .max(15, "เลขที่บัญชีธนาคารต้องมีไม่เกิน 15 ตัวเลข")
    .required("กรุณากรอกเลขที่บัญชีธนาคาร"),
});

const checkBankAccountLaos = async ({
  bankCode,
  bankAccountNumber,
}: {
  bankCode: string;
  bankAccountNumber: string;
}) => {
  try {
    const { data } = await http.post(apiPath.auth.checkBankAccountLaos, {
      bank_code: bankCode,
      bank_account_number: bankAccountNumber,
    });
    return data;
  } catch {
    return false;
  }
};

const checkBankAccount = async ({
  bankCode,
  bankAccountNumber,
}: {
  bankCode: string;
  bankAccountNumber: string;
}) => {
  try {
    const { data } = await http.post(apiPath.auth.checkBankAccount, {
      bank_code: bankCode,
      bank_account_number: bankAccountNumber,
    });
    return data;
  } catch {
    return false;
  }
};

export default function FormVerifyBank() {
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [nextStep, updateInput] = useSignUpStore(
    (state) => [state.nextStep, state.updateInput],
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { bankCode, bankAccountNumber } = data;

    if(!config?.is_verify_bank){
      const re = /^\d*[1-9]\d*$/
      if(!bankAccountNumber.match(re)){
        enqueueSnackbar("ข้อมูลบัญชีธนาคารไม่ถูกต้อง", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return
      }

      setLoading(true);
      const bankAccountLaos = await checkBankAccountLaos({ bankCode, bankAccountNumber });
      setLoading(false);
      if (!bankAccountLaos) {
        enqueueSnackbar("ตรวจสอบบัญชีธนาคารไม่สำเร็จ", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
      if (!bankAccountLaos.status) {
        enqueueSnackbar(bankAccountLaos.msg, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }

      updateInput({ bankCode, bankAccountNumber, fullName: '' });
      return nextStep();
    }

    setLoading(true);
    const bankAccount = await checkBankAccount({ bankCode, bankAccountNumber });
    setLoading(false);
    if (!bankAccount) {
      enqueueSnackbar("ตรวจสอบบัญชีธนาคารไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    if (!bankAccount.status) {
      enqueueSnackbar(bankAccount.msg, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    if (!bankAccount.data.fullname) {
      enqueueSnackbar("ไม่พบข้อมูลบัญชีธนาคาร", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    const { fullname: fullName } = bankAccount.data;
    updateInput({ bankCode, bankAccountNumber, fullName });
    nextStep();
  };

  return (
    <Box p={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="bankCodeLabel">ธนาคาร</InputLabel>
            <Controller
              name="bankCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  labelId="bankCodeLabel"
                  id="bankCode"
                  value={value}
                  label="ธนาคาร"
                  sx={{
                    ...(errors.bankCode && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900] || "error.main",
                      }
                    })
                  }}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.bankCode)}
                >
                  {supportBanks.map((bank) => (
                    <MenuItem key={bank.code} value={bank.short_name}>
                      {bank.official_name_th}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.bankCode && (
              <FormHelperText 
              sx={{
                ...(errors.bankCode && {
                  borderColor: (theme) => theme.gradient[900] || "error.main",
                })
              }}>
                {errors.bankCode.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="bankAccountNumber"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  id="bankAccountNumber"
                  label="เลขที่บัญชีธนาคาร"
                  sx={{
                    ...(errors.bankAccountNumber && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: "16px",
                      "& .MuiInputLabel-root": {
                        color: (theme) => theme.gradient[900] || "error.main",
                      }
                    })
                  }}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.bankAccountNumber)}
                  placeholder="เลขที่บัญชีธนาคาร"
                />
              )}
            />
            {errors.bankAccountNumber && (
              <FormHelperText 
              sx={{
                ...(errors.bankAccountNumber && {
                  borderColor: (theme) => theme.gradient[900] || "error.main",
                })
              }}>
                {errors.bankAccountNumber.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            fullWidth
            style={{ height: "50px" }}
            sx={{
              color: (theme) => theme.name === 'preset14' ? '#fff' : 'default',
              backgroundColor: (theme) => theme.name === 'preset14' ? '#33B7FF' : 'default',
              "&:hover": {
                color: (theme) => theme.name === 'preset14' ? '#fff' : 'default',
                backgroundColor: (theme) => theme.name === 'preset14' ? '#33B7FF' : 'default',
              },
            }}
          >
            ตรวจสอบบัญชี
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
